import { Inject } from '@angular/core';

@Inject({
  provided: 'root'
})
export class AppTextsService {
  public texts: Map<string, string> = new Map<string, string>();

  constructor() {
    this.init();
  }

  init() {
     this.texts.set('MIGRATION_EMAIL_TITLE', 'Connexion');
     this.texts.set('MIGRATION_PASS_TITLE', 'Connexion');
     this.texts.set('MESSAGE_ALREDY_MIGRATED', 'Cette adresse e-mail est déjà associée à un compte m3 PASS.');
     this.texts.set('MESSAGE_MIGRATED', 'Vous allez être redirigés automatiquement sur la page de connexion ou cliquez sur le bouton ci-dessous pour vous connecter directement');
     this.texts.set('MESSAGE_NO_ACCOUNT', 'Cette adresse e-mail [[EMAIL]] n\'est associée à aucun compte m3 PASS.');
     this.texts.set('MESSAGE_NO_ACCOUNT_DESCRIPTION', 'Cliquez sur le bouton ci-dessous pour créer votre compte m3 PASS directement');
     this.texts.set('MESSAGE_MIGRATED_DISCOVER', 'Découvrez également tous les avantages du m3 PASS en cliquant sur le lien vers le site officiel');
     this.texts.set('CONNECTION', 'Connexion');
     this.texts.set('CREATION', 'Créer mon compte m3 PASS');
     this.texts.set('EMAIL', 'E-mail');
     this.texts.set('EMAIL_DESCRIPTION', 'Saisissez l\'email de votre compte m3');
     this.texts.set('PASS_DESCRIPTION', 'Saisissez le mot de passe de votre compte m3 pour l\'email:');
     this.texts.set('EMAIL_PLACEHOLDER', 'Votre e-mail');
     this.texts.set('PASSWORD', 'Mot de passe');
     this.texts.set('NEW_PASSWORD', 'Nouveau mot de passe');
     this.texts.set('CONFIRM_NEW_PASSWORD', 'Confirmation de votre mot de passe');
     this.texts.set('PASSWORD_PLACEHOLDER', 'Votre mot de passe');
     this.texts.set('FORGOT_PASSWORD', 'Mot de passe perdu');
     this.texts.set('RESET_PASSWORD_TITLE', 'Créer un nouveau mot de passe');
     this.texts.set('FORGOT_PASSWORD_SUCCESS', 'Votre mot de passe a été changé avec succès');
     this.texts.set('PASSWORD_NOT_VALID', 'Le mot de passe doit être composé de 8 caractères minimum et contenir au moins 1 lettre en majuscule, 1 lettre en miniscule et 1 chiffre');
     this.texts.set('PASSWORD_NOT_MATCH', 'Les mots de passe ne sont pas identiques');
     this.texts.set('VERIFICATION_CODE', 'Code de confirmation');
     this.texts.set('VERIFICATION_CODE_EMAIL', 'envoyé par mail');
     this.texts.set('BACK', 'Retour');
     this.texts.set('CANCEL', 'Annuler');
     this.texts.set('CLOSE', 'Fermer');
     this.texts.set('NEXT', 'Suivant');
     this.texts.set('PASS', 'Passer');
     this.texts.set('MIGRATE', 'Activer mon m3 PASS');
     this.texts.set('VALIDATE', 'Valider');
     this.texts.set('LOGIN_TITLE', 'Migrer votre compte');
     this.texts.set('RESET_EMAIL_DESCRIPTION', 'Veuillez saisir l\'adresse e-mail associée à votre compte.');
     this.texts.set('RESET_PASSWORD_INTRODUCTION', 'Un code de confirmation vous a été envoyé par email à l\'instant');
     this.texts.set('RESET_PASSWORD_DESCRIPTION', 'Veuillez saisir le code de confirmation qui vous a été envoyé à l\'adresse e-mail');
     this.texts.set('ERRORS_REQUIRED_FIELD', 'Ce champ est requis');
     this.texts.set('ERRORS_MISSING_FIELD', 'Certains champs sont manquants');
     this.texts.set('ERRORS_EMAIL_FORMAT', 'Adresse e-mail invalide');
     this.texts.set('ERRORS_NOT_AUTHORIZED', 'Adresse e-mail ou mot de passe incorrects');
     this.texts.set('ERRORS_NETWORK', 'Une erreur est survenue, veuillez réessayer plus tard');
     this.texts.set('ERRORS_LIMIT_EXCEEDED', 'Trop de tentatives, veuillez réessayer plus tard');
     this.texts.set('ERRORS_CODE_MISSMATCH', 'Code de confirmation invalide');
     this.texts.set('ERRORS_PASSWORD_FORMAT', 'Le mot de passe doit être composé de 8 caractères minimum et contenir au moins 1 lettre en majuscule, 1 lettre en miniscule et 1 chiffre');
     this.texts.set('SLIDE1_DESCRIPTION1', 'Avec ');
     this.texts.set('SLIDE1_DESCRIPTION2', 'un identifiant unique pour l\'ensemble de nos services');
     this.texts.set('SLIDE3_DESCRIPTION1', 'Bénéficiez de toutes nos offres et avantages et vivez ');
     this.texts.set('SLIDE3_DESCRIPTION2', 'Genève autrement.');
     this.texts.set('SLIDE2_DESCRIPTION', 'vous simplifie la vie');
     this.texts.set('SLIDE3_CREATE', 'Créer mon compte m3 PASS');
     this.texts.set('SLIDE3_CONNEXION', 'Connexion');
     this.texts.set('SLIDE3_ALREADY_ACCOUNT', 'J\'ai déjà un compte m3');
   }

   public getText(key: string): string {
    return this.texts.get(key);
   }


}
