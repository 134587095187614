import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';


export class UserInfo {
    firstName?: string;
    lastName?: string;
}

export class AccountInfo {
    username?: string;
    source: string;
}

@Injectable({
    providedIn: 'root'
})
export class MigrationService {
    private readonly API_URL = `${environment.api}`;

    constructor(
        private httpClient: HttpClient
    ) { }

    // ---- PUBLIC API(s) ----

    requestAcccountMigration(user: string, password: string): Observable<UserInfo> {
        return this.httpClient
            .post<UserInfo>(`${this.API_URL}/migration`, {
              username: user,
              password
            });
    }

    checkAcccountMigration(user: string): Observable<AccountInfo> {
        return this.httpClient
            .get<AccountInfo>(`${this.API_URL}?username=${user}`);
    }
}
