import { Injectable } from '@angular/core';
import { AmplifyAuthProvider } from './providers/amplify-auth-provider';

@Injectable()
export class AuthService {
    constructor(
        private authProvider: AmplifyAuthProvider
    ) { }

    async resetPasswordByUsername(username: string): Promise<void> {
        return this.authProvider.resetPwd(username);
    }

    async resetPasswordSubmit(username: string, code: string, newPassword: string): Promise<void> {
        return this.authProvider.resetPasswordSubmit(username, code, newPassword);
    }

}
