import { NgForm, FormBuilder, FormGroup, Validators, FormGroupDirective, FormControl } from '@angular/forms';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ErrorStateMatcher } from '@angular/material/core';

import { AuthService } from 'src/app/common/auth';
import { AccountInfo, MigrationService } from 'src/app/common/service/migration.service';
import { AppTextsService } from './app-texts.service';
import { ActivatedRoute } from '@angular/router';

class CrossFieldErrorMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl, form: FormGroupDirective | NgForm): boolean {
        return control.dirty && form.invalid;
    }
}

enum LoginState {
    MIGRATION_EMAIL = 'MIGRATION_EMAIL',
    MIGRATION_PASS = 'MIGRATION_PASS',
    MIGRATED = 'MIGRATED',
    RESET_PASSWORD = 'RESET_PASSWORD'
}

const APP_M3_TENANT_ID = 'ch.m3.tenants';

@Component({
    selector: 'app-migration-page',
    templateUrl: './migration.page.html',
    styleUrls: ['./migration.page.scss'],
})
export class MigrationPageComponent implements OnInit {
    @ViewChild('pwd', { static: false })
    public pwdInput: ElementRef;

    public redirectUri: string;
    public alreadyMigratedUser: boolean;
    public isSourceTenant: boolean;
    public tenantWithoutAccount: boolean;
    public userEmail: string;
    public LoginState = LoginState;
    public currentLoginState: LoginState = LoginState.MIGRATION_EMAIL;
    public errorMatcher = new CrossFieldErrorMatcher();
    public resetEmailForm: FormGroup;
    public resetPasswordForm: FormGroup;
    public error: string;
    public processing = false;
    public configOtp = {
        allowNumbersOnly: true,
        length: 6,
        isPasswordInput: false,
        disableAutoFocus: true,
        placeholder: ''
    };

    constructor(
        private authService: AuthService,
        private fb: FormBuilder,
        private migrationService: MigrationService,
        private appTextService: AppTextsService,
        private activatedRoute: ActivatedRoute,
    ) { }

    ngOnInit() {
        this.resetEmailForm = this.fb.group({
            email: ['', [Validators.required, Validators.email]]
        });

        this.resetPasswordForm = this.fb.group({
            code: ['', [Validators.required]],
            password: ['', [Validators.required, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/)]],
            passwordConfirmation: ['', [Validators.required]]
        }, {
            validators: [this.passwordValidator]
        });
        this.activatedRoute.queryParams.subscribe(params => {
            this.redirectUri = params.redirectUri;
            this.isSourceTenant = this.redirectUri && this.redirectUri.indexOf(APP_M3_TENANT_ID) > -1;
        });
    }

    get password() {
        return this.resetPasswordForm.get('password');
    }

    get passwordConfirmation() {
        return this.resetPasswordForm.get('passwordConfirmation');
    }
    get code() {
        return this.resetPasswordForm.get('code');
    }

    get email() {
        return this.resetEmailForm.get('email');
    }

    // --- HANDLER(s) ---

    showState(state: LoginState) {
        if (this.currentLoginState !== state) {
            this.currentLoginState = state;
            this.error = undefined;
            this.resetPasswordForm.reset();
        }
    }

    async checkAccountType() {
        this.error = undefined;
        this.userEmail = this.email.value;
        if (this.email.hasError('required')) {
            this.showState(LoginState.MIGRATION_EMAIL);
            this.error = 'Ce champ est requis';
        } else if (this.email.hasError('email')) {
            this.error = 'Adresse e-mail invalide';
        } else {
            try {
            this.processing = true;
            this.userEmail = this.email.value;
            this.migrationService
                .checkAcccountMigration(this.userEmail)
                .subscribe(
                (accountInfo: AccountInfo) => {
                    switch (accountInfo.source) {
                        case 'keycloak':
                            this.currentLoginState = LoginState.MIGRATED;
                            this.alreadyMigratedUser = true;
                            this.redirectToSource(true);
                            break;
                        case 'cognito':
                            console.log(' cognito ');
                            this.currentLoginState = LoginState.MIGRATION_PASS;
                            this.processing = false;
                            break;
                        case 'none':
                            const isTenant = this.redirectUri && this.redirectUri.indexOf(APP_M3_TENANT_ID) > -1;
                            if (isTenant) {
                                this.currentLoginState = LoginState.MIGRATED;
                                this.processing = false;
                                this.tenantWithoutAccount = true;
                            } else {
                                this.currentLoginState = LoginState.MIGRATION_PASS;
                                this.processing = false;
                            }
                            break;
                    }
                },
                () => {
                    console.error('check account fail');
                    this.processing = false;
                    this.error =  'Une erreur est survenue, veuillez réessayer plus tard!';
                });
            } catch (error) {
            this.error = error.message ? error.message : 'Une erreur est survenue, veuillez réessayer plus tard!';
            }
        }
    }

    async onMigration(form: NgForm) {
        return this.migration(this.userEmail, form.value.password);
    }

    resetEmail() {
        console.log(' reset email');
        this.error = undefined;
        if (this.userEmail) {
            this.processing = true;

            this.authService
                .resetPasswordByUsername(this.email.value)
                .then(() => {
                    this.error = undefined;
                    console.log(' reset pass requested pass to Reset PAss');
                    this.currentLoginState = LoginState.RESET_PASSWORD;
                }).catch((error) => {
                    switch (error.code) {
                        case 'LimitExceededException':
                            this.error = 'Trop de tentatives, veuillez réessayer plus tard';
                            break;
                        default:
                            this.error = 'Une erreur est survenue, veuillez réessayer plus tard';
                            break;
                        }
                    }).finally(() => this.processing = false);
        } else  {
            this.error = 'Adresse e-mail est requis';
        }
    }

    resetPassword() {
        this.error = undefined;

        if (this.resetPasswordForm.valid) {
            this.processing = true;

            this.authService
                .resetPasswordSubmit(this.email.value, this.code.value, this.password.value)
                .then(() => {
                    this.error = undefined;
                    console.log('resetPasswordSubmit OK ask migration' );
                    return this.migration(this.email.value, this.password.value);
                })
                .catch((error) => {
                    switch (error.code) {
                        case 'LimitExceededException':
                            this.error = this.contentText('ERRORS_LIMIT_EXCEEDED');
                            break;
                        case 'CodeMismatchException':
                            this.error = this.contentText('ERRORS_CODE_MISSMATCH');
                            break;
                        default:
                            this.error = this.contentText('ERRORS_NETWORK');
                            break;
                    }
                })
                .finally(() => this.processing = false);
        } else if (this.code.hasError('required') || this.password.hasError('required') || this.passwordConfirmation.hasError('required')) {
            this.error = this.contentText('ERRORS_MISSING_FIELD');
        } else if (this.password.hasError('pattern') || this.passwordConfirmation.hasError('pattern')) {
            this.error = this.contentText('ERRORS_PASSWORD_FORMAT');
        } else if (this.resetPasswordForm.hasError('passwordDoNotMatch')) {
            this.error = this.contentText('ERRORS_PASSWORD_MISSMATCH');
        }
    }

    onOtpChange(change: string) {
        this.code.setValue(change);
        if (change.length === 6) {
            this.code.setErrors(null);
        } else {
            this.code.setErrors(Validators.required);
        }
    }


    contentText(key: string) {
      return this.appTextService.getText(key);
    }

    contentTextandRemplaceEmail(key: string) {
      return this.appTextService.getText(key).replace('[[EMAIL]]', this.userEmail);
    }

    // todo test
    onCancel() {
        switch (this.currentLoginState) {
            case LoginState.MIGRATION_EMAIL:
                const reditectSourceUri = this.redirectUri ;
                setTimeout(() => {
                    window.location.href = reditectSourceUri;
                }, 0, reditectSourceUri);
                break;
            case LoginState.MIGRATION_PASS:
                this.showState(LoginState.MIGRATION_EMAIL);
                break;
            case LoginState.RESET_PASSWORD:
                this.showState(LoginState.MIGRATION_EMAIL);
                break;
            case LoginState.MIGRATED:
                this.showState(LoginState.MIGRATION_EMAIL);
                break;
        }

    }

    // --- HELPER(s) ---

    private passwordValidator(form: FormGroup) {
        if (form && form.get('passwordConfirmation') && form.get('passwordConfirmation').dirty) {
            const condition = form.get('password') && (form.get('password').value !==  form.get('passwordConfirmation').value);
            return condition ? { passwordDoNotMatch: true } : null;
        }
        return null;
    }
    async migration(email: string, password: string) {
        this.error = undefined;
        try {
          this.processing = true;
          this.migrationService
            .requestAcccountMigration(email, password)
            .subscribe(
              () => {
                this.currentLoginState = LoginState.MIGRATED;
                this.redirectToSource(true);
              },
              (error) => {
                this.processing = false;
                const errorCode = error.error.error.code ? error.error.error.code : error.error.error.errorCode;
                switch (errorCode) {
                  case 'UserNotFoundException':
                  case 'NotAuthorizedException':
                  case 'UserNotFound':
                  case 'NotAuthorized':
                  case 'InvalidCredentials':
                    if (errorCode.includes('attempts exceeded')) {
                      this.error =
                        'Votre compte est temporairement bloqué, veuillez réessayer plus tard';
                    } else {
                      this.error = 'Adresse e-mail ou mot de passe incorrects';
                    }
                    break;
                  case 'UserAlreadyMigrated':
                    this.currentLoginState = LoginState.MIGRATED;
                    this.alreadyMigratedUser = true;
                    this.redirectToSource(true);
                    break;
                  default:
                    this.error = 'Veuillez réessayer plustard!' + '\n' + error.message;
                    break;
                }
              }
            );
        } catch (error) {
           this.error = 'Veuillez réessayer plustard!';
        }
    }

    public redirectToSource(hasM3PASS: boolean) {
        const urlParameter = `?action=${hasM3PASS ? 'login' : 'register'}`;
        const reditectSourceUri = this.redirectUri + urlParameter;
        setTimeout(() => {
            window.location.href = reditectSourceUri;
        }, 5000, reditectSourceUri);
    }

    public redirectToSourceWithoutWait(hasM3PASS: boolean) {
        const urlParameter = `?action=${hasM3PASS ? 'login' : 'register'}`;
        const reditectSourceUri = this.redirectUri + urlParameter;
        setTimeout(() => {
            window.location.href = reditectSourceUri;
        }, 0, reditectSourceUri);
    }

}
