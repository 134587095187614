import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NgOtpInputModule } from 'ng-otp-input';

import { MigrationPageComponent } from './migration.page';
import { AppUtils } from 'src/app/common/service/app-utils.service';
import { AppTextsService } from './app-texts.service';
import { MigrationRoutingModule } from './migration-routing.module';
import { MatCardModule } from '@angular/material/card';
import { IonicModule } from '@ionic/angular';

@NgModule({
    declarations: [
        MigrationPageComponent
    ],
    imports: [
        MigrationRoutingModule,
        CommonModule,
        FlexLayoutModule,
        FormsModule,
        MatIconModule,
        MatButtonModule,
        MatInputModule,
        MatFormFieldModule,
        MatProgressSpinnerModule,
        ReactiveFormsModule,
        NgOtpInputModule,
        MatSnackBarModule,
        MatCardModule,
        IonicModule
    ],
    providers: [AppTextsService]
})
export class MigrationModule {
    constructor(
        private appUtils: AppUtils
    ) {
        this.appUtils.addSvgIcons([
            'app/logo',
            'app/m3-gerance'
        ]);
    }
}
