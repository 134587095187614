import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MigrationPageComponent } from './pages/migration/migration.page';


const routes: Routes = [
  { path: 'migration', component: MigrationPageComponent },
  {
    path: '',
    loadChildren: () => import('./pages/m3-pass/m3-pass.module').then(m => m.M3PassModule)
  },
  { path: '**', pathMatch: 'full', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
