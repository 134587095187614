import { Injectable, Inject } from '@angular/core';

import Amplify, { Auth } from 'aws-amplify';
import { AuthProvider, AUTH_PROVIDER_CONFIG } from './auth-provider';

@Injectable()
export class AmplifyAuthProvider implements AuthProvider {
    constructor(
        @Inject(AUTH_PROVIDER_CONFIG) private config: any
    ) {
        try {
            Amplify.configure({
                Auth: this.config
            });
        } catch (err) {
            console.error(err);
        }
    }


    resetPwd(username: string): Promise<void> {
        return new Promise((res, rej) => {
            Auth.forgotPassword(username)
                .then((data) => {
                    res(data);
                }).catch((err) => rej(err));
        });
    }

    resetPasswordSubmit(username: string, code: string, newPassword: string): Promise<void> {
        return new Promise((res, rej) => {
            Auth.forgotPasswordSubmit(username, code, newPassword)
            .then((data) => res(data))
            .catch((err) => rej(err));
        });
    }

}
