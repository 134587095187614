import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MigrationPageComponent } from './migration.page';


const routes: Routes = [
    {
        path: '',
        component: MigrationPageComponent },
    { path: '**', pathMatch: 'prefix', redirectTo: '' }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class MigrationRoutingModule { }
