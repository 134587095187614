import { NgModule, ModuleWithProviders } from '@angular/core';

import { AmplifyAuthProvider } from './providers/amplify-auth-provider';
import { AUTH_PROVIDER_CONFIG } from './providers/auth-provider';
import { AuthService } from './auth.service';

@NgModule({
    imports: []
})
export class AuthModule {
    public static forRoot(config: any): ModuleWithProviders<any> {
        return {
            ngModule: AuthModule,
            providers: [
                {
                    provide: AUTH_PROVIDER_CONFIG,
                    useValue: config
                },
                AuthService,
                AmplifyAuthProvider
            ]
        };
    }
}
